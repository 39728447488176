<template>
  <div>
    <v-card flat class="pa-3 mt-2">
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">GAS SLIP LIQUIDATION</h4></v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form ref="form" class="multi-col-validation mt-6">
          <v-row>
            <v-row>
              <v-col md="3" cols="12">
                <v-select
                  v-model="duration"
                  dense
                  outlined
                  label="Duration"
                  :items="['1-15', '16-30/31']"
                  @change="selected_duration"
                  :rules="rules.combobox_rule"
                ></v-select>
              </v-col>
              <v-col md="3" cols="12">
                <v-select
                  v-model="gas_slip_po_id"
                  dense
                  outlined
                  label="PO #"
                  :items="po_items"
                  item-value="id"
                  item-text="po_no"
                  :rules="rules.combobox_rule"
                ></v-select>
              </v-col>
              <v-col md="3" cols="12">
                <v-text-field
                  v-model="date_of_request"
                  label="Date"
                  dense
                  outlined
                  type="date"
                  :rules="rules.default_max_45_character_and_no_empty_rule"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-select
                  v-model="particulars"
                  dense
                  outlined
                  label="Particulars"
                  :items="['DIESEL', 'GASOLINE','KEROSENE']"
                  :rules="rules.combobox_rule"
                ></v-select>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="credited_amount"
                  label="Amount"
                  dense
                  outlined
                  type="number"
                  min="0"
                  :rules="rules.default_max_45_character_and_no_empty_rule"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-select
                  v-model="auto_id"
                  dense
                  outlined
                  label="Auto Name"
                  :items="auto_items"
                  item-value="id"
                  item-text="auto_name"
                  :rules="rules.combobox_rule"
                ></v-select>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="driver"
                  label="Driver"
                  dense
                  outlined
                  :rules="rules.default_max_75_character_and_no_empty_rule"
                ></v-text-field>
              </v-col>
              <!-- alert -->
              <v-col cols="12" v-show="alert">
                <v-alert color="warning" text class="mb-0">
                  <div class="d-flex align-start">
                    <v-icon color="warning">
                      {{ icons.mdiAlertOutline }}
                    </v-icon>

                    <div class="ms-3">
                      <p class="text-base font-weight-medium mb-1">
                        {{ alert_message }}
                      </p>
                    </div>
                  </div>
                </v-alert>
              </v-col>

              <v-col cols="12">
                <v-btn color="primary" class="me-3 mt-4" @click="save_request" v-if="!saving"> Save
                  changes
                </v-btn>
                <v-progress-circular :size="50" :width="5" color="primary" indeterminate
                                     v-else></v-progress-circular>
              </v-col>
            </v-row>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-text>
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-center text-uppercase">PO#</th>
              <th class="text-center text-uppercase">Date</th>
              <th class="text-center text-uppercase">Particular</th>
              <th class="text-center text-uppercase">Amount</th>
              <th class="text-center text-uppercase">Auto</th>
              <th class="text-center text-uppercase">Driver</th>
              <th class="text-center text-uppercase">Action</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="item in liquidations" :key="item.id">
              <td class="text-center">
                {{ item.gas_slip_po_id }}
              </td>
              <td class="text-center">
                {{ item.date }}
              </td>
              <td class="text-center">
                {{ item.particulars }}
              </td>
              <td class="text-center">
                {{ formatPrice(item.amount) }}
              </td>
              <td class="text-center">
                {{ item.auto_id }}
              </td>
              <td class="text-center">
                {{ item.driver }}
              </td>
              <td class="text-center">
                <div>
                  <v-icon
                    v-if="!is_deleting"
                    class="mr-2"
                    color="error"
                    @click="delete_payment(liquidations.map(function(x) {return x.id; }).indexOf(item.id))"
                  >
                    {{icons.mdiDelete}}
                  </v-icon>
                  <v-progress-circular color="info" indeterminate
                                       v-else></v-progress-circular>
                </div>
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
    <snack-bar-dialog :snackbar_flag="this.snackbar" :color="this.snackbar_color"
                      :snackbar_text="this.snackbar_text"/>
  </div>
</template>

<script>
  import {mdiAlertOutline, mdiCloudUploadOutline, mdiDelete} from '@mdi/js'
  import {mapGetters, mapActions} from 'vuex'
  import moment from 'moment'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  const initialState = () => {
    return {
      duration: '',
      gas_slip_po_id: 0,
      po_items: [],
      date_of_request: '',
      particulars: '',
      credited_amount: 0,
      auto_id: 0,
      auto_items: [],
      driver: '',

      liquidations: [],

      saving: false,
      alert: false,
      alert_message: '',
      is_deleting: false
    }
  }
  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiAlertOutline,
          mdiCloudUploadOutline,
          mdiDelete,
        },
      }
    },
    created() {
      this.initialize_data()
    },
    data() {
      return initialState()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text']),
      ...mapGetters('authentication', ['branch_id', 'month_start', 'month_end', 'month_of', 'user_id']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('gas_slip_po', ['get_available_gas_slip']),
      ...mapActions('auto_name', ['get_avaialable_auto']),
      ...mapActions('gas_slip_liquidation', ['get_gas_slip_liquidations', 'save_gas_slip_liquidations', 'delete_gas_slip_liquidation']),
      reset() {
        Object.assign(this.$data, initialState())
        this.selected_duration()
        this.initialize_data()
      },
      initialize_data() {
        const data = new FormData()
        data.append('branch_id', this.branch_id)
        this.get_available_gas_slip(data)
          .then(response => {
            this.po_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
        this.get_avaialable_auto(data)
          .then(response => {
            this.auto_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      selected_duration() {
        const data = new FormData()
        data.append('branch_id', this.branch_id)
        data.append('duration', this.duration)
        data.append('month_of', this.month_of)
        this.get_gas_slip_liquidations(data)
          .then(response => {
            this.liquidations = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      save_request() {
        this.saving = true
        this.alert = false
        var flag = true
        // var flag = false
        // if (
        //   moment(this.date_of_request, 'YYYY-MM-DD').format('MMMM D, YYYY') ===
        //   moment(this.month_start, 'MMMM D, YYYY').format('MMMM D, YYYY') ||
        //   moment(this.date_of_request, 'YYYY-MM-DD').format('MMMM D, YYYY') ===
        //   moment(this.month_end, 'MMMM D, YYYY').format('MMMM D, YYYY')
        // ) {
        //   flag = true
        // } else {
        //   var compareDate = moment(this.date_of_request, 'YYYY-MM-DD')
        //   var startDate = moment(this.month_start, 'MMMM D, YYYY')
        //   var endDate = moment(this.month_end, 'MMMM D, YYYY')
        //
        //   flag = compareDate.isBetween(startDate, endDate) //false in this case
        // }
        if (flag === false) {
          this.date_of_request = ''
          this.alert = true
          this.alert_message = 'Date is Not Belong in Transaction Month!'
          this.saving = false
        } else {
          if (this.$refs.form.validate()) {
            const data = new FormData()
            data.append('branch_id', this.branch_id)
            data.append('gas_slip_po_id', this.gas_slip_po_id)
            data.append('auto_id', this.auto_id)
            data.append('date', moment(this.date_of_request, 'YYYY-MM-DD').format('MMMM D, YYYY'))
            data.append('amount', this.credited_amount)
            data.append('particulars', this.particulars)
            data.append('driver', this.driver.toUpperCase())
            data.append('duration', this.duration)
            data.append('month_of', this.month_of)
            this.save_gas_slip_liquidations(data)
              .then(response => {
                this.change_snackbar({
                  show: true,
                  color: 'success',
                  text: response.data,
                })
                this.reset()
              })
              .catch(error => {
                this.alert = true
                this.alert_message = error
                this.saving = false
              })
          } else {
            this.alert = true
            this.alert_message = 'Please Fill up the field/s'
            this.saving = false
          }
        }
      },
      delete_payment(index) {
        this.is_deleting = true
        var data = this.liquidations[index]
        this.delete_gas_slip_liquidation({
          id: data.id,
          gas_slip_po_id: data.gas_slip_po_id,
        })
          .then(response => {
            this.change_snackbar({
              show: true,
              color: 'error',
              text: response.data,
            })
            this.is_deleting = false
            this.reset()
          })
          .catch(error => {
            console.log(error)
          })
      },
    },
  }
</script>
